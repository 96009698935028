import "index.scss"

import "syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"
import sal from 'sal.js'

import "custom.scss"

console.info("Bridgetown is loaded!")

sal();